<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-12">
      <div class="row mx-0 justify-content-center">
        <div class="col-12 mb-3 text-center font19" >
          Bank Details
        </div>
        <NewBankDetails v-if="!user || !latestDebitOrder || isNewBank" v-model:isNewBank="isNewBank" v-model:bankAccountDetails="bankAccountDetailsValue" />
        <ExistingBankDetails v-else v-model:isNewBank="isNewBank" v-model:bankAccountDetails="bankAccountDetailsValue" />
      </div>
      <div class="row mx-0 justify-content-center">
        <div class="col-10 my-4 text-center divider">
        </div>
      </div>
      <PaymentDetails v-model:paymentFrequencyDetails="paymentFrequencyDetailsValue" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    NewBankDetails: defineAsyncComponent(() => import('./NewBankDetails.vue')),
    PaymentDetails: defineAsyncComponent(() => import('./PaymentDetails.vue')),
    ExistingBankDetails: defineAsyncComponent(() => import('./ExistingBankDetails.vue'))
  },
  props: ['bankAccountDetails', 'paymentFrequencyDetails'],
  emits: ['update:bankAccountDetails', 'update:paymentFrequencyDetails'],
  name: 'DebitOrderDetails',
  data () {
    return {
      isNewBank: false
    }
  },
  computed: {
    ...mapGetters([
      'user', 'debitOrders'
    ]),
    latestDebitOrder () {
      if (this.debitOrders && this.debitOrders.length > 0) {
        return this.debitOrders[0]
      } else {
        return null
      }
    },
    bankAccountDetailsValue: {
      get () {
        return this.bankAccountDetails
      },
      set (val) {
        this.$emit('update:bankAccountDetails', val)
      }
    },
    paymentFrequencyDetailsValue: {
      get () {
        return this.paymentFrequencyDetails
      },
      set (val) {
        this.$emit('update:paymentFrequencyDetails', val)
      }
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
</style>
